import React from "react";
import { DArrowIcon, DiscardIcon,DownArrowIconwhite } from "../helper/Icon";
import tellogo   from "./../assets/svg/tel_logo2.svg";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Community() {
  const { t, i18n  } = useTranslation();
    // 创建跳转链接的函数，包含语言参数
    const getStartedUrl = () => {
      const currentLanguageCode = i18n.language;
      return `https://app.sanketsfx.com/#/?language=${currentLanguageCode}`;
    };
  return (
    <>
      <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10   relative z-10  ">
        <div className=" flex md:flex-row flex-col text-center md:text-start items-center justify-between gap-6   py-10 sm:py-16 xl:py-20 2xl:py-24 px-4 sm:px-10 xl:px-20 2xl:px-24 rounded-[30px] bg-opacity-20  bg-img-comminity">
          <div className="max-w-[610px]">
            <h2 className="font-graphik_black text-white text-xl sm:text-3xl xl:text-4xl 2xl:text-[44px]">
              {t('Community.join_our_community')}
            </h2>
            <p  className="my-4 text-base font-graphik text-[rgba(255,255,255,0.70)]">
              {t('Community.welcome_message')}
              <span className="font-graphik_medium text-white">

              </span>

            </p>
          </div>
          <div>
            <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  const url = getStartedUrl();
                  window.open(url, "_blank", "noopener noreferrer");
                }}
                className="bg-primary text-white px-4 sm:px-5 xl:px-7 py-2 xl:py-3.5 rounded-full flex items-center gap-1.5
             lg:gap-2.5 font-graphik_medium text-white text-sm sm:text-base transition-all duration-300 ease-in-out hover:shadow-xl hover:shadow-primary group whitespace-nowrap"
            >
              
            <span>
              {/* <img
                  src={tellogo}
                  alt="Telegram"
                  className="w-5 h-5"
              /> 这里替换为Telegram的SVG图标 */}
            </span>
              {t('common.get_started')}
              <span>
            <DownArrowIconwhite />
          </span>
            </Link>


          </div>
        </div>
      </div>
    </>
  );
}

export default Community;
