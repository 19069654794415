import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Layout from "./components/hoc/Layout";
import HomePage from "./components/pages/home/HomePage";
import Trading from "./components/pages/trading/Trading";
import Security from "./components/pages/security/Security";
import Company from "./components/pages/company/Company";
import ReferralProgram from "./components/pages/ReferralProgram.jsx/ReferralProgram";
import PrivacyPolicy from "./components/pages/privacy-policy/PrivacyPolicy";
import VulnerabilityDisclosurePolicy from "./components/pages/vulnerability-disclosure-policy/VulnerabilityDisclosurePolicy";
import UserRegistrationAgreement from "./components/pages/user-registration-agreement/UserRegistrationAgreement";
import PrivacyAgreement from "./components/pages/privacy-agreement/PrivacyAgreement";
import Disclaimer from "./components/pages/disclaimer/Disclaimer";
import InformationDisclosureStatement from "./components/pages/information-disclosure-statement/InformationDisclosureStatement";

// 引入 i18n 配置
import './i18n';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const languageFromUrl = urlParams.get('language');

    if (languageFromUrl && i18n.language !== languageFromUrl) {
      // 如果URL中有语言参数且与当前语言不同，则切换语言
      i18n.changeLanguage(languageFromUrl);
    } else if (!languageFromUrl) {
      // 如果URL中没有语言参数，设置默认语言并更新URL
      const defaultLanguage = i18n.language || 'en'; // 使用i18n的当前语言或'en'
      urlParams.set('language', defaultLanguage);
      navigate(`${location.pathname}?${urlParams.toString()}`, { replace: true });
    }
  }, [location, i18n, navigate]);

  return (
      <div className="App">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" index element={<HomePage />} />
            <Route path="/trading" element={<Trading />} />
            <Route path="/security" element={<Security />} />
            <Route path="/company" element={<Company />} />
            <Route path="/referral-program" element={<ReferralProgram />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/user-registration-agreement" element={<UserRegistrationAgreement />} />
            <Route path="/privacy-agreement" element={<PrivacyAgreement />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/information-disclosure-statement" element={<InformationDisclosureStatement />} />
            <Route path="/vulnerability-disclosure-policy" element={<VulnerabilityDisclosurePolicy />} />
          </Route>
        </Routes>
      </div>
  );
}

function RootApp() {
  return (
      <BrowserRouter>
        <App />
      </BrowserRouter>
  );
}

export default RootApp;
