import React from "react";
import Community from "./Community";
import BottomBar from "./BottomBar";

function Footer() {
  return (
    <>
      <BottomBar />
    </>
  );
}

export default Footer;
