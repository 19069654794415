import React from "react";
import Hero from "../../common/Hero";
import NumberSet from "./NumberSet";
import ForexExchange from "./ForexExchange ";
import SubCompanys from "./SubCompanys";
import BestTools from "./BestTools";
import HowWork from "./HowWork";
import Community from "../../common/Community";
function HomePage() {
  return (
    <>
      <Hero />
      <NumberSet />
      <ForexExchange />
      <SubCompanys />
      <BestTools />
      <HowWork />
      <Community />
    </>
  );
}

export default HomePage;
