import React from "react";
import { useTranslation } from "react-i18next";
import marginimg from "./../../assets/png/marginimg.png";

function Margin() {
  const { t } = useTranslation();

  return (
      <div className="py-6 md:py-10">
        <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
          <div className="flex flex-col-reverse lg:flex-row-reverse justify-between gap-8 items-center">
            <div className="max-w-[280px] sm:max-w-[400px] lg:max-w-[500px] xl:max-w-[755px]">
              <img src={marginimg} alt="marginimg" className="w-full" />
            </div>
            <div className="lg:max-w-[600px]">
              <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] capitalize">
                {t("margin.title")}
              </h2>
              <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%]">
                {t("margin.description")}
              </p>
              <div className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%]">
                <div className="text-secondary text-sm leading-[160%]">
                  <p>
                  <span className="font-graphik_medium">
                    {t("margin.feature_1_title")}:{" "}
                  </span>
                  </p>
                  {t("margin.feature_1_description")}
                </div>
                <div className="text-secondary text-sm leading-[160%]">
                  <p>
                  <span className="font-graphik_medium">
                    {t("margin.feature_2_title")}:{" "}
                  </span>
                  </p>
                  {t("margin.feature_2_description")}
                </div>
                <div className="text-secondary text-sm leading-[160%]">
                  <p>
                  <span className="font-graphik_medium">
                    {t("margin.feature_3_title")}:{" "}
                  </span>
                  </p>
                  {t("margin.feature_3_description")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Margin;
