import React from "react";

import tabimg0 from "./../../assets/png/tab.png"; // 引入第一个图像
import tabimg1 from "./../../assets/png/market.png"; // 引入第二个图像
import tabimg2 from "./../../assets/png/deposit.png"; // 引入第三个图像

import { useTranslation } from "react-i18next";

function SmartEngine({ value }) {
  const { t } = useTranslation();

  // 根据 value 值动态设置图像
  const getImageSrc = () => {
    switch (value) {
      case 0:
        return tabimg0;
      case 1:
        return tabimg1;
      case 2:
        return tabimg2;
      default:
        return tabimg0;
    }
  };

  const renderTopTitle = () => {
    if (value === 0) {
      return t('common.step_1');
    } else if (value === 1) {
      return t('common.step_2');
    } else if (value === 2) {
      return t('common.step_3');
    }
  };

  const renderTitle = () => {
    if (value === 0) {
      return t('common.register_and_verify_profile');
    } else if (value === 1) {
      return t('common.deposit_funds');
    } else if (value === 2) {
      return t('common.enter_the_market');
    }
  };

  const renderDescription = () => {
    if (value === 0) {
      return t('SmartEngine.register_description');
    } else if (value === 1) {
      return t('SmartEngine.deposit_description');
    } else if (value === 2) {
      return t('SmartEngine.enter_market_description');
    }
  };

  return (
      <>
        <div className="py-8 lg:py-12  text-start  ">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 ">
            <div className="flex flex-col-reverse lg:flex-row-reverse justify-between gap-8 items-center">
              <div className="max-w-[280px] sm:max-w-[400px] lg:max-w-[755px]">
                <img src={getImageSrc()} alt="foreximg" className="w-full" />
              </div>
              <div style={{width:"50%"}}>
                <p className="capitalize tracking-[0.84px] text-sm text-primary  ">
                  {renderTopTitle()}
                </p>
                <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] uppercase">
                  {renderTitle()}
                </h2>
                <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%] ">
                  {renderDescription()}
                  <span className="font-graphik_medium text-black">
                  {" "}

                </span>{" "}

                </p>
                {/* <ul className="flex flex-col gap-3 sm:gap-4    2xl:gap-8 list-disc ps-4 md:ps-5">
                <li className="text-secondary text-sm leading-[160%] ">
                  Our Smart Engine connects to leading Forex exchange Binance,
                  Kraken, LMAX, HitBTC and Bitfinex, finding the best route to
                  execute your orders in milliseconds.
                </li>
              </ul>*/}
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default SmartEngine;
