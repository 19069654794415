import React, { Children } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";

function Layout({ Children }) {
  return (
    <>
      <Header />
      {Children}
      <Footer />
    </>
  );
}

export default Layout;
