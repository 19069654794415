import React from "react";
import kycimg from "./../../assets/png/kycimg.png";
import { useTranslation } from "react-i18next";

function KYC() {
  const { t } = useTranslation();

  return (
      <div className="py-6 md:py-10 mb-10 lg:mb-20">
        <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
          <div className="flex flex-col-reverse lg:flex-row-reverse justify-between gap-8 items-center">
            <div className="max-w-[280px] sm:max-w-[400px] lg:max-w-[500px] xl:max-w-[755px]">
              <img src={kycimg} alt="KYC Process Image" className="w-full" />
            </div>
            <div className="lg:max-w-[600px]">
              <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] capitalize">
                {t("KYC.title")}
              </h2>
              <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%]">
                {t("KYC.description")}
              </p>
              <ul className="flex flex-col gap-3 sm:gap-4 2xl:gap-8 list-disc ps-4 md:ps-5">
                <li className="text-secondary text-sm leading-[160%]">
                <span className="font-graphik_medium">
                  {t("KYC.kyc_requirements_title")}:{" "}
                </span>
                  {t("KYC.kyc_requirements_description")}
                </li>
                <li className="text-secondary text-sm leading-[160%]">
                <span className="font-graphik_medium">
                  {t("KYC.verification_process_title")}:{" "}
                </span>
                  {t("KYC.verification_process_description")}
                </li>
                <li className="text-secondary text-sm leading-[160%]">
                <span className="font-graphik_medium">
                  {t("KYC.privacy_data_protection_title")}:{" "}
                </span>
                  {t("KYC.privacy_data_protection_description")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  );
}

export default KYC;
