import React, { useState } from "react";
import { threeTabdata } from "../../helper/Helper";
import SmartEngine from "./SmartEngine";
import { useTranslation } from "react-i18next";

function Threetabs() {
    const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className="w-full max-w-[690px] mx-auto rounded-full bg-[#FAFAFF] my-6 lg:my-8">
        <div className="flex items-center overflow-x-scroll sm:overflow-hidden whitespace-nowrap gap-3 overflow-y-hidden  w-full scrol-hide pb-0">
          {threeTabdata.map((item, index) => (
            <button
              key={index}
              className={`w-full sm:w-1/3 px-4 py-3 md:py-6 rounded-full text-center  transition-colors duration-300 focus:outline-none font-graphik_medium text-sm  sm:text-base ${
                activeTab === index
                  ? "bg-[#EEF0FD] text-black "
                  : "text-secondary"
              }`}
              onClick={() => setActiveTab(index)}
            >
                {t(item.name)}
            </button>
          ))}
        </div>
      </div>
      <div>
        {activeTab === 0 ? <SmartEngine value={0} /> : ""}
        {activeTab === 1 ? <SmartEngine value={1} /> : ""}
        {activeTab === 2 ? <SmartEngine value={2} /> : ""}
      </div>
    </>
  );
}

export default Threetabs;
