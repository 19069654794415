import React from "react";
import Threetabs from "./Threetabs";
import { useTranslation } from "react-i18next";

function BestTools() {
  const { t } = useTranslation();
  return (
    <>
      <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 py-10 md:py-14">
        <div className="text-center">
          <p className="text-sm text-secondary tracking-[0.84px] uppercase mb-3 lg:mb-4">
            {t('BestTools.how_to_start')}
          </p>
          <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] capitalize">
            {t('BestTools.three_easy_steps')}
          </h2>
          <Threetabs />
        </div>
      </div>
    </>
  );
}

export default BestTools;
