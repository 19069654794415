import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


//  language参数默认的英文
const urlParams = new URLSearchParams(window.location.search);
if (!urlParams.has('language')) {
    urlParams.set('language', 'en');  // 默认语言设置为英文
    window.history.replaceState(null, null, `?${urlParams.toString()}`);
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
