import React from "react";
import Hero from "./Hero";
import Advantages from "./Advantages";
import CooperatingOrganizations from "./CooperatingOrganizations";
import LegalDocuments from "./LegalDocuments";
import Community from "./Community";

function Company() {
  return (
    <>
      <Hero />
      <Advantages />
      <CooperatingOrganizations />
      <LegalDocuments />
      <Community />
    </>
  );
}

export default Company;
