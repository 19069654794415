import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./../assets/svg/logo.svg";
import { useTranslation } from 'react-i18next';

function BottomBar() {
  const { t } = useTranslation();
  useEffect(() => {
    // Tawk.to Script
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/66bd9221146b7af4a43aafc7/1i5a85kti';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
      <>
        <div className="bg-black pt-40 -mt-20 lg:-mt-32 ">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
            <div className="flex flex-row flex-wrap md:flex-nowrap justify-between gap-y-8 gap-6 py-14 ">
              <div className="w-full ">
                <div>
                  <Link to={"/"} className="flex items-center gap-1 sm:gap-2">
                    <img
                        src={Logo}
                        alt="logo"
                        className="max-w-[140px] sm:max-w-[191px]"
                    />
                  </Link>
                  <p className="my-4 xl:py-0 text-base font-graphik text-[rgba(255,255,255,0.50)] ">
                    {t('BottomBar.investing_risk_warning')}
                    </p>
                  <p className="my-4 xl:py-0 text-base font-graphik text-[rgba(255,255,255,0.50)] ">
                    {t('BottomBar.sankets_registration_info')}
                  </p>
                  <p className="my-4 xl:py-0 text-base font-graphik text-[rgba(255,255,255,0.50)] ">
                    {t('BottomBar.authorized_entities_warning')}
                    {t('BottomBar.complex_products_warning')}
                   </p>
                  <p className="my-4 xl:py-0 text-base font-graphik text-[rgba(255,255,255,0.50)] ">
                    {t('BottomBar.service_restrictions')}
                    </p>
                  <p className="my-4 xl:py-0 text-base font-graphik text-[rgba(255,255,255,0.50)] ">
                    {t('BottomBar.no_investment_advice')}
                    </p>
                  <p className="my-4 xl:py-0 text-base font-graphik text-[rgba(255,255,255,0.50)] ">
                    {t('BottomBar.website_interaction_warning')}
                    </p>
                  <p className="my-4 xl:py-0 text-base font-graphik text-[rgba(255,255,255,0.50)] ">
                    {t('BottomBar.copy_right_warning')}
                  </p>
                </div>
              </div>


            </div>
          </div>

          <div className="border-t border-[rgba(255,255,255,0.10)]">
            <div className="py-5 sm:py-7 lg:py-10 flex md:flex-row flex-col items-center justify-between gap-4 max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
              <div className="flex items-center gap-4 lg:gap-6 xl:gap-8 2xl:gap-10 flex-wrap justify-center">
                <Link
                    to={"/user-registration-agreement"}
                    className="text-sm text-[rgba(255,255,255,0.50)] font-graphik transition-all duration-300 ease-in-out hover:text-white hover:underline"
                >
                  {t('BottomBar.user_registration_agreement')}
                </Link>
                <Link
                    to={"/privacy-agreement"}
                    className="text-sm text-[rgba(255,255,255,0.50)] font-graphik transition-all duration-300 ease-in-out hover:text-white hover:underline"
                >
                  {t('common.privacy_agreement')}
                </Link>
                <Link
                    to={"/privacy-policy"}
                    className="text-sm text-[rgba(255,255,255,0.50)] font-graphik transition-all duration-300 ease-in-out hover:text-white hover:underline"
                >
                  {t('common.risk_alert_agreement')}
                </Link>
                <Link
                    to={"/disclaimer"}
                    className="text-sm text-[rgba(255,255,255,0.50)] font-graphik transition-all duration-300 ease-in-out hover:text-white hover:underline"
                >
                  {t('common.disclaimer')}
                </Link>
                <Link
                    to={"/information-disclosure-statement"}
                    className="text-sm text-[rgba(255,255,255,0.50)] font-graphik transition-all duration-300 ease-in-out hover:text-white hover:underline"
                >
                  {t('common.information_disclosure_statement')}
                </Link>
                <Link
                    to={"/vulnerability-disclosure-policy"}
                    className="text-sm text-[rgba(255,255,255,0.50)] font-graphik transition-all duration-300 ease-in-out hover:text-white hover:underline"
                >
                  {t('common.vulnerability_disclosure_policy')}
                </Link>
              </div>
              <p className="text-sm text-[rgba(255,255,255,0.50)] font-graphik">
                {t('BottomBar.footer_copyright')}
              </p>
            </div>
          </div>
        </div>
      </>
  );
}

export default BottomBar;
