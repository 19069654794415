import React, { useEffect, useState } from "react";
import Logo from "./../assets/svg/logo.svg";
import { headerLinks } from "../helper/Helper";
import { HamburgerIcon, RightArrowIcon } from "../helper/Icon";
import MobileHeader from "./MobileHeader";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const languageMap = {
  en: "English",
  es: "Español",
  ja: "日本語",
  tr: "Türkçe",
  "zh-tw": "繁體中文",
};

const Header = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState(languageMap[i18n.language] || "English");

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lng) => {
    const langFull = languageMap[lng] || "English";
    i18next.changeLanguage(lng);
    setCurrentLang(langFull);
    setLanguageMenuOpen(false);
    const url = new URL(window.location);
    url.searchParams.set('language', lng);
    window.history.pushState({}, '', url);
  };

  const toggleLanguageMenu = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };

  useEffect(() => {
    // 从URL参数中读取语言并设置
    const urlParams = new URLSearchParams(location.search);
    const languageFromUrl = urlParams.get('language');
    if (languageFromUrl && i18n.language !== languageFromUrl) {
      i18n.changeLanguage(languageFromUrl);
    }

    const body = document.body;
    const html = document.documentElement;
    if (isOpen) {
      body.style.overflow = "hidden";
      html.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    }
    return () => {
      body.style.overflow = "auto";
      html.style.overflow = "auto";
    };
  }, [isOpen, location, i18n]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const currentLanguageCode = i18n.language;
    setCurrentLang(languageMap[currentLanguageCode] || "English");
  }, [i18n.language]);

  const getStartedUrl = () => {
    const currentLanguageCode = i18n.language;
    return `https://app.sanketsfx.com/#/?language=${currentLanguageCode}`;
  };

  return (
      <>
        <div
            className={`fixed top-0 w-full z-50 font-milk ${
                isScrolled ? "transition-all duration-300 ease-in-out bg-primary" : ""
            }`}
        >
          <div className="relative max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 z-10 font-poppins">
            <div className="flex justify-between items-center border-b border-[rgba(255,255,255,0.10)] py-4 lg:py-6">
              <Link to={"/"} className="flex items-center gap-1 sm:gap-2">
                <img
                    src={Logo}
                    alt="logo"
                    className="max-w-[140px] xl:max-w-[191px]"
                />
              </Link>
              <div className="items-center gap-x-4 md:gap-x-6 xl:gap-x-10 hidden lg:flex justify-center">
                {headerLinks.map((item, index) => (
                    <Link
                        key={index}
                        to={item.link}
                        className="text-white text-base transition-all duration-300 ease-in-out hover:opacity-70 font-normal"
                    >
                      {t(item.name)}
                    </Link>
                ))}
              </div>

              <div className="flex items-center">
                <div className="hidden lg:flex">
                  <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        const url = getStartedUrl();
                        window.open(url, "_blank", "noopener noreferrer");
                      }}
                      className="bg-white px-5 xl:px-7 py-2 xl:py-3.5 rounded-full flex items-center gap-1.5 lg:gap-2.5 font-graphik_medium text-primary text-base transition-all duration-300 ease-in-out hover:shadow-xl hover:shadow-primary group"
                  >
                    {t("common.get_started")}
                    <RightArrowIcon />
                  </Link>
                </div>
                <div className="relative ml-21 lg:ml-auto">
                  <button
                      onClick={toggleLanguageMenu}
                      className="nav-link px-3 dropdown-toggle text-white"
                      aria-label="Language selector"
                      aria-haspopup="true"
                  >
                    <FontAwesomeIcon icon={faGlobe} className="mr-1" /> {currentLang}
                  </button>
                  {languageMenuOpen && (
                      <div className="absolute right-0 mt-2 w-40 shadow-lg bg-white ring-1 ring-black ring-opacity-5" style={{ borderRadius: '8px', overflow: 'hidden' }}>
                        <div className="bg-white" role="menu" aria-orientation="vertical">
                          {Object.entries(languageMap).map(([code, name]) => (
                              <button
                                  key={code}
                                  onClick={() => changeLanguage(code)}
                                  className={`block w-full text-left ${
                                      currentLang === name ? "bg-[#162290] text-white" : "text-gray-700 hover:bg-gray-100"
                                  }`}
                                  style={{ padding: "10px 16px", margin: "0", border: "none", borderRadius: "0" }}
                              >
                                {t(`common.languageOptions.${name}`)}
                              </button>
                          ))}
                        </div>
                      </div>
                  )}
                </div>
              </div>

              <div className="lg:hidden ml-4">
                <button onClick={toggleOpen}>
                  <HamburgerIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        <Outlet />
        <div
            className={`fixed top-0 left-0 w-full h-full bg-primary lg:bg-transparent transform ${
                isOpen ? "translate-x-0" : "-translate-x-full"
            } transition-transform duration-300 ease-in-out z-50`}
        >
          <MobileHeader toggleOpen={toggleOpen} />
        </div>
      </>
  );
};

export default Header;
