import React from "react";
import Advantagesimg from "./../../assets/png/advantagesimg.png";
import { useTranslation } from 'react-i18next';
function Advantages() {
  const { t } = useTranslation();
  return (
    <>
      <div className="py-12 md:py-16  xl:py-20">
        <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 ">
          <div className="flex flex-col-reverse lg:flex-row justify-between gap-8 items-center">
            <div className="max-w-[280px] sm:max-w-[400px] lg:max-w-[500px] xl:max-w-[755px]">
              <img src={Advantagesimg} alt="foreximg" className="w-full" />
            </div>
            <div className="lg:max-w-[600px]  ">
              <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] capitalize ">
                {t('Advantages.mission_and_vision')}
              </h2>
              <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%] ">
                {t('Advantages.mission_description')}
              </p>
              <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%] ">
                {t('Advantages.trading_environment_promise')}
              </p>
              <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%] ">
                {t('Advantages.vision_description')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Advantages;
