import React from "react";
import { useTranslation } from "react-i18next";

function PrivacyAgreement() {
  const { t } = useTranslation();

  return (
      <>
        <div className="bg-primary pb-20 pt-40">
          <h1 className="text-white text-center font-bold !leading-[120%] text-3xl sm:text-4xl xl:text-6xl 2xl:7xl my-4">
            {t('PrivacyAgreement.title')}
          </h1>
        </div>
        <div className="bg-white my-16 md:my-20 pb-20">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
            <h2 className="text-primary font-graphik_semibold mt-6 text-lg lg:text-xl">
              {t('PrivacyAgreement.introduction')}
            </h2>
            <br />
            <p className="text-black text-base sm:text-lg lg:text-xl">
              {t('PrivacyAgreement.introduction_description')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('PrivacyAgreement.privacy_importance')}
            </p>

            <h2 className="text-primary font-graphik_semibold mt-6 text-lg lg:text-xl">
              {t('PrivacyAgreement.data_we_may_collect')}
            </h2>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.data_we_may_collect_description')}
            </p>
            <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.identity_data')}
              </span>: {t('PrivacyAgreement.identity_data_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.financial_data')}
              </span>: {t('PrivacyAgreement.financial_data_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.transaction_data')}
              </span>: {t('PrivacyAgreement.transaction_data_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.telephone_recordings_data')}
              </span>: {t('PrivacyAgreement.telephone_recordings_data_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.technical_data')}
              </span>: {t('PrivacyAgreement.technical_data_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.profile_data')}
              </span>: {t('PrivacyAgreement.profile_data_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.general_data')}
              </span>: {t('PrivacyAgreement.general_data_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.marketing_and_communications_data')}
              </span>: {t('PrivacyAgreement.marketing_and_communications_data_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                {t('PrivacyAgreement.special_categories_data')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                {t('PrivacyAgreement.legal_entity_verification_data')}
              </li>
            </ul>

            <h2 className="text-primary font-graphik_semibold mt-6 text-lg lg:text-xl">
              {t('PrivacyAgreement.how_we_use_your_data')}
            </h2>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.how_we_use_your_data_description')}
            </p>
            <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.creating_trading_account')}
              </span>: {t('PrivacyAgreement.creating_trading_account_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.identity_verification')}
              </span>: {t('PrivacyAgreement.identity_verification_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.improving_products_services')}
              </span>: {t('PrivacyAgreement.improving_products_services_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.securing_website_services')}
              </span>: {t('PrivacyAgreement.securing_website_services_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.notifying_changes')}
              </span>: {t('PrivacyAgreement.notifying_changes_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.data_analytics')}
              </span>: {t('PrivacyAgreement.data_analytics_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.legal_compliance')}
              </span>: {t('PrivacyAgreement.legal_compliance_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.promotional_offers')}
              </span>: {t('PrivacyAgreement.promotional_offers_description')}
              </li>
            </ul>

            <h2 className="text-primary font-graphik_semibold mt-6 text-lg lg:text-xl">
              {t('PrivacyAgreement.disclosure_of_personal_data')}
            </h2>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.disclosure_of_personal_data_description')}
            </p>
            <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.service_providers')}
              </span>: {t('PrivacyAgreement.service_providers_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.professional_advisers')}
              </span>: {t('PrivacyAgreement.professional_advisers_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.regulators_authorities')}
              </span>: {t('PrivacyAgreement.regulators_authorities_description')}
              </li>
            </ul>

            <h2 className="text-primary font-graphik_semibold mt-6 text-lg lg:text-xl">
              {t('PrivacyAgreement.data_security')}
            </h2>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.data_security_description')}
            </p>
            <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.encryption_personal_information')}
              </span>
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.back_up_servers')}
              </span>
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.ongoing_monitoring_security_measures')}
              </span>
              </li>
            </ul>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.data_breaches_procedures')}
            </p>

            <h2 className="text-primary font-graphik_semibold mt-6 text-lg lg:text-xl">
              {t('PrivacyAgreement.cookies')}
            </h2>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.cookies_description')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.traffic_log_cookies')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.cookie_management')}
            </p>

            <h2 className="text-primary font-graphik_semibold mt-6 text-lg lg:text-xl">
              {t('PrivacyAgreement.data_retention')}
            </h2>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.data_retention_description')}
            </p>
            <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.satisfy_legal_obligations')}
              </span>
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.assert_exercise_legal_claims')}
              </span>
              </li>
            </ul>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.retention_period')}
            </p>

            <h2 className="text-primary font-graphik_semibold mt-6 text-lg lg:text-xl">
              {t('PrivacyAgreement.your_rights_access_data')}
            </h2>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.your_rights_access_data_description')}
            </p>
            <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.access_personal_information')}
              </span>: {t('PrivacyAgreement.access_personal_information_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.right_rectification')}
              </span>: {t('PrivacyAgreement.right_rectification_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.right_be_forgotten')}
              </span>: {t('PrivacyAgreement.right_be_forgotten_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.right_object_restrict_processing')}
              </span>: {t('PrivacyAgreement.right_object_restrict_processing_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                -<span className="text-primary font-graphik_semibold">
                {t('PrivacyAgreement.right_data_portability')}
              </span>: {t('PrivacyAgreement.right_data_portability_description')}
              </li>
            </ul>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.consent_privacy_policy')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.data_concerns')}
            </p>

            <h2 className="text-primary font-graphik_semibold mt-6 text-lg lg:text-xl">
              {t('PrivacyAgreement.inform_changes')}
            </h2>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.inform_changes_description')}
            </p>

            <h2 className="text-primary font-graphik_semibold mt-6 text-lg lg:text-xl">
              {t('PrivacyAgreement.changes_privacy_policy')}
            </h2>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-4">
              {t('PrivacyAgreement.changes_privacy_policy_description')}
            </p>
          </div>
        </div>
      </>
  );
}

export default PrivacyAgreement;
