import React from "react";
import { useTranslation } from "react-i18next";

function UserRegistrationAgreement() {
    const { t } = useTranslation();

    return (
        <>
            <div className="bg-primary pb-20 pt-40">
                <h1 className="text-white text-center font-bold !leading-[120%] text-3xl sm:text-4xl xl:text-6xl 2xl:7xl my-4">
                    {t('userRegistrationAgreement.title')}
                </h1>
            </div>
            <div className="bg-white my-16 md:my-20 pb-20">
                <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
                    <p className="text-black text-base sm:text-lg lg:text-xl">
                        {t('userRegistrationAgreement.intro')}
                    </p>
                    <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
                        {t('userRegistrationAgreement.agreementIntro')}
                    </p>
                    <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
                        {[...Array(38).keys()].map(i => (
                            <li key={i} className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {i + 1}. {t(`userRegistrationAgreement.section${i + 1}.title`)}:
                </span>{" "}
                                {t(`userRegistrationAgreement.section${i + 1}.description`)}
                            </li>
                        ))}
                    </ul>
                    <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
                        {t('userRegistrationAgreement.conclusion')}
                    </p>
                </div>
            </div>
        </>
    );
}

export default UserRegistrationAgreement;
