import React from "react";
import rewardimg from "./../../assets/png/rewardimg.png";
import { useTranslation } from "react-i18next";

function Rewards() {
  const { t } = useTranslation();

  return (
      <>
        <div className="py-6 md:py-10">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
            <div className="flex flex-col-reverse lg:flex-row-reverse justify-between gap-8 items-center">
              <div className="max-w-[280px] sm:max-w-[400px] lg:max-w-[500px] xl:max-w-[755px]">
                <img src={rewardimg} alt="foreximg" className="w-full" />
              </div>
              <div className="lg:max-w-[600px]">
                <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] capitalize">
                  {t('Rewards.title')}
                </h2>
                <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%]">
                  {t('Rewards.description')}
                </p>
                <ul className="flex flex-col gap-3 sm:gap-4 2xl:gap-8 list-disc ps-4 md:ps-5">
                  <li className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t('Rewards.details.types_of_rewards.title')}:{" "}
                  </span>
                    {t('Rewards.details.types_of_rewards.description')}
                  </li>
                  <li className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t('Rewards.details.reward_tiers.title')}:{" "}
                  </span>
                    {t('Rewards.details.reward_tiers.description')}
                  </li>
                  <li className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t('Rewards.details.payment_methods.title')}:{" "}
                  </span>
                    {t('Rewards.details.payment_methods.description')}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default Rewards;
