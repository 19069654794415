import React from "react";
import { Link } from "react-router-dom";
import homebank from "./../../assets/svg/home-bank.svg";
import lamborghini from "./../../assets/svg/lamborghini.svg";
import hoonigan from "./../../assets/svg/hoonigan.svg";
import enhanced from "./../../assets/svg/enhanced.svg";
import sitezone from "./../../assets/svg/sitezone.svg";
import { useTranslation } from "react-i18next";

function SubCompanys() {
  const { t } = useTranslation();

  return (
      <>
        <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 py-10 md:py-14 lg:py-20">
          <div className="flex flex-wrap justify-center lg:justify-between gap-4">
            <Link to={"/"}>
              <img
                  src={homebank}
                  alt={t('SubCompanys.alt_message')}
                  className="max-w-[150px] md:max-w-fit"
              />
            </Link>
            <Link to={"/"}>
              <img
                  src={lamborghini}
                  alt={t('SubCompanys.alt_message')}
                  className="max-w-[150px] md:max-w-fit"
              />
            </Link>
            <Link to={"/"}>
              <img
                  src={hoonigan}
                  alt={t('SubCompanys.alt_message')}
                  className="max-w-[150px] md:max-w-fit"
              />
            </Link>
            <Link to={"/"}>
              <img
                  src={enhanced}
                  alt={t('SubCompanys.alt_message')}
                  className="max-w-[150px] md:max-w-fit"
              />
            </Link>
            <Link to={"/"}>
              <img
                  src={sitezone}
                  alt={t('SubCompanys.alt_message')}
                  className="max-w-[150px] md:max-w-fit"
              />
            </Link>
          </div>
        </div>
      </>
  );
}

export default SubCompanys;
