import React from "react";
import { useTranslation } from "react-i18next";

function VulnerabilityDisclosurePolicy() {
  const { t } = useTranslation();

  // 辅助函数，用于获取翻译项并检查是否为数组
  const getItems = (key) => {
    const items = t(key, { returnObjects: true });
    return Array.isArray(items) ? items : [];
  };

  return (
      <>
        <div className="bg-primary pb-20 pt-40">
          <h1 className="text-white text-center font-bold !leading-[120%] text-3xl sm:text-4xl xl:text-6xl 2xl:7xl my-4">
            {t("VulnerabilityDisclosurePolicy.title")}
          </h1>
        </div>
        <div className="bg-white my-16 md:my-20 pb-20">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
            <p className="text-black text-base sm:text-lg lg:text-xl mb-6">
              {t("VulnerabilityDisclosurePolicy.intro")}
            </p>

            <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
              <li className="text-black text-base sm:text-lg lg:text-xl list-none">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.programTerms")}
              </span>
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.acceptanceOfTerms.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.acceptanceOfTerms.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
                {t("VulnerabilityDisclosurePolicy.noLegalAction")}
                <br />
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.importantNote.title")}
              </span>
                {t("VulnerabilityDisclosurePolicy.importantNote.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.softwareScope.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.softwareScope.description")}
                <br />
                {getItems("VulnerabilityDisclosurePolicy.softwareScope.inScopeItems").map((item, index) => (
                    <React.Fragment key={index}>
                      - {item}
                      <br />
                    </React.Fragment>
                ))}
                Any other software or acquisitions, including but not limited to the following, are out of scope:
                <br />
                {getItems("VulnerabilityDisclosurePolicy.softwareScope.outOfScopeItems").map((item, index) => (
                    <React.Fragment key={index}>
                      - {item}
                      <br />
                    </React.Fragment>
                ))}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.webAppScope.title")}
              </span>
                <br />
                <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.webAppScope.inScope.title")}
              </span>
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.webAppScope.inScope.items").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
                <br />
                <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.webAppScope.outOfScope.title")}
              </span>
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.webAppScope.outOfScope.items").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.mobileAppScope.title")}
              </span>
                <br />
                <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.mobileAppScope.inScope.title")}
              </span>
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.mobileAppScope.inScope.items").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
                <br />
                <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.mobileAppScope.outOfScope.title")}
              </span>
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.mobileAppScope.outOfScope.items").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
                <br />
                <span className="font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.mobileAppScope.android")}
              </span>
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.mobileAppScope.androidItems").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
                <br />
                <span className="font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.mobileAppScope.ios")}
              </span>
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.mobileAppScope.iosItems").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.responseTime.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.responseTime.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.eligibilityRequirements.title")}
              </span>
                <br />
                To participate in this Program and be eligible for a Benefit, you must:
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.eligibilityRequirements.requirements").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
                <br />
                You are NOT eligible if:
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.eligibilityRequirements.ineligibility").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
                <br />
                {t("VulnerabilityDisclosurePolicy.eligibilityRequirements.note")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.covenants.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.covenants.description")}
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.covenants.items").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.submissionRequirements.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.submissionRequirements.description")}
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.submissionRequirements.items").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
                <br />
                {t("VulnerabilityDisclosurePolicy.submissionRequirements.submitTo")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.benefitPayment.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.benefitPayment.description")}
                <ul className="list-disc ml-6 list-none">
                  {getItems("VulnerabilityDisclosurePolicy.benefitPayment.items").map((item, index) => (
                      <li key={index}>- {item}</li>
                  ))}
                </ul>
                <br />
                {t("VulnerabilityDisclosurePolicy.benefitPayment.note")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.ownershipOfSubmission.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.ownershipOfSubmission.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.waiver.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.waiver.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.termination.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.termination.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.confidentiality.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.confidentiality.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.indemnity.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.indemnity.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.limitationOfLiability.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.limitationOfLiability.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.applicableLawAndJurisdiction.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.applicableLawAndJurisdiction.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.miscellaneous.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.miscellaneous.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.legalChangeOfProgramTerms.title")}
              </span>
                <br />
                {t("VulnerabilityDisclosurePolicy.legalChangeOfProgramTerms.description")}
              </li>

              <li className="text-black text-base sm:text-lg lg:text-xl list-none">
                <br />
                <span className="text-primary font-graphik_semibold">
                {t("VulnerabilityDisclosurePolicy.reservesRights")}
              </span>
              </li>
            </ul>
          </div>
        </div>
      </>
  );
}

export default VulnerabilityDisclosurePolicy;
