import React from "react";
//import refferimg from "./../../assets/png/reffer.png";
import { useTranslation } from "react-i18next";

function Hero() {
  const { t } = useTranslation();

  return (
      <>
        <div className="hero-bg2">
          <div className="py-14 2xl:py-20">
            <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 pt-20 xl:pt-40 font-poppins">
              <div className="flex md:flex-row flex-col justify-between gap-6">
                <div className="max-w-[600px] lg:mt-20">
                  <h1 className="text-white font-bold !leading-[120%] text-3xl sm:text-4xl xl:text-6xl 2xl:7xl my-4">
                    {t('ReferralProgramHero.title')}
                  </h1>
                  <p className="text-white text-base sm:text-lg font-normal !leading-[180%] opacity-80 xl:pe-20">
                    {t('ReferralProgramHero.welcome_message')}
                  </p>
                  <h3 className="my-4 md:my-5 lg:mt-8 text-white text-lg sm:text-xl lg:text-2xl font-graphik_semibold">
                    {t('ReferralProgramHero.overview_title')}
                  </h3>{" "}
                  <p className="text-white text-base font-normal !leading-[180%] opacity-80 xl:pe-20">
                    {t('ReferralProgramHero.overview_description')}
                  </p>
                  <ul className="flex flex-col mt-4 md:mt-6 gap-2 sm:gap-3 2xl:gap-6 list-disc ps-4 md:ps-5 white-dot">
                    <li className="text-white text-sm leading-[160%]">
                    <span className="font-graphik_medium">
                      {" "}
                      {t('ReferralProgramHero.program_introduction.title')}:{" "}
                    </span>
                      {t('ReferralProgramHero.program_introduction.description')}
                    </li>{" "}
                    <li className="text-white text-sm leading-[160%]">
                    <span className="font-graphik_medium">
                      {t('ReferralProgramHero.how_it_works.title')}:{" "}
                    </span>
                      {t('ReferralProgramHero.how_it_works.description')}
                    </li>{" "}
                    <li className="text-white text-sm leading-[160%]">
                    <span className="font-graphik_medium">
                      {t('ReferralProgramHero.benefits.title')}:{" "}
                    </span>
                      {t('ReferralProgramHero.benefits.description')}
                    </li>{" "}
                  </ul>{" "}
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default Hero;
