import React from "react";
import tradingimg from "./../../assets/png/tradingimg.png";
import { useTranslation } from "react-i18next";

function TradingHours() {
  const { t } = useTranslation();

  // 获取awards数据并确保它是一个数组
  const awards = t('tradingHours.awards', { returnObjects: true });

  return (
      <>
        <div className="py-12 md:py-16  xl:py-20">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 ">
            <div className="flex flex-col-reverse lg:flex-row justify-between gap-8 items-center">
              <div className="max-w-[280px] sm:max-w-[400px] lg:max-w-[500px] xl:max-w-[755px]">
                <img src={tradingimg} alt="foreximg" className="w-full" />
              </div>
              <div className="lg:min-w-[600px]">
                <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] capitalize ">
                  {t('tradingHours.title')}
                </h2>
                <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%] ">
                  {t('tradingHours.description')}
                </p>
                <div className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%] ">
                  {Array.isArray(awards) && awards.map((award, index) => (
                      <div key={index} className="text-secondary text-sm leading-[160%]">
                        <p>
                      <span className="font-graphik_medium">
                        {award.year}: <img src="https://static.cdnroute.io/lp/exnova/svstatic/assets/reviews/stars.svg" alt="stars" />
                      </span>{" "}
                        </p>
                        {award.description}
                      </div>
                  ))}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default TradingHours;
