import React from "react";
import Hero from "./Hero";
import DepositsWithdrawals from "./DepositsWithdrawals";
import KYC from "./KYC";
import Community from "./Community";

function Security() {
  return (
    <>
      <Hero />
      <DepositsWithdrawals />
      <KYC />
      <Community />
    </>
  );
}

export default Security;
