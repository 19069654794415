import React from "react";
import { Link } from "react-router-dom";
import { DArrowIcon, DownArrowIcon } from "../helper/Icon";
import heroimg from "./../assets/png/hero.svg";
import { useTranslation } from 'react-i18next';

function Hero() {
    const { t, i18n } = useTranslation();

    // 创建跳转链接的函数，包含语言参数
    const getStartedUrl = () => {
        const currentLanguageCode = i18n.language;
        return `https://app.sanketsfx.com/#/?language=${currentLanguageCode}`;
    };

    return (
        <>
            <div className="hero-bg">
                <div className="py-14 2xl:py-20">
                    <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-6 pt-8 xl:pt-6 font-poppins">
                        <div className="flex md:flex-row flex-col justify-between gap-6" style={{ border: "0px red solid" }}>
                            <div className="max-w-[700px] lg:mt-20" style={{ border: "0px red solid" }}>
                                <p className="text-base font-medium leading-[180%] text-white uppercase tracking-[1.6px]">
                                    {t('Hero.welcome_to_sankets_fx')}
                                </p>
                                <h1 className="text-white font-bold !leading-[120%] text-4xl sm:text-5xl xl:text-7xl 2xl:text-[90px] my-4">
                                    {t('Hero.your_ultimate_mobile_trading_solution')}
                                </h1>
                                <p className="text-white text-base sm:text-lg lg:text-xl font-normal !leading-[180%] opacity-80 xl:pe-20">
                                    {t('Hero.trade_description')}
                                </p>
                                <div className="flex my-5 xl:my-8 items-center gap-3 sm:gap-4">
                                    <Link
                                        to="/"
                                        onClick={(e) => {
                                            e.preventDefault(); // 阻止默认行为
                                            const url = getStartedUrl(); // 动态生成跳转链接
                                            window.open(url, "_blank", "noopener noreferrer");
                                        }}
                                        className="bg-white px-4 sm:px-5 xl:px-7 py-2 xl:py-3.5 rounded-full flex items-center gap-1.5 lg:gap-2.5 font-graphik_medium text-black text-sm sm:text-base transition-all duration-300 ease-in-out hover:shadow-xl hover:shadow-primary group"
                                    >
                                        {t('common.get_started')}
                                        <span>
                                            <DownArrowIcon />
                                        </span>
                                    </Link>
                                   
                                </div>
                            </div>
                            <div className="w-2/3 lg:mt-1" style={{ border: "0px red solid", display: "inline-block", width: "100%" }}>
                                <img style={{ border: "0px blue solid", width: "100%", height: "100%" }} src={heroimg} alt="phone img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hero;
