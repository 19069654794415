import React from "react";
import { useTranslation } from "react-i18next";

function Community() {
  const { t } = useTranslation();

  return (
      <>
        <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 relative z-10">
          <div className="flex md:flex-row flex-col text-center md:text-start items-center justify-between gap-6 py-10 sm:py-16 xl:py-20 2xl:py-24 px-4 sm:px-10 xl:px-20 2xl:px-24 rounded-[30px] bg-opacity-20 bg-img-comminity">
            <div className="max-w-[742px] text-center mx-auto">
              <h2 className="font-graphik_black text-white text-xl sm:text-3xl xl:text-4xl 2xl:text-[44px]">
                {t('ReferralProgramCommunity.title')}
              </h2>
              <p className="my-4 text-base font-graphik text-[rgba(255,255,255,0.70)]">
                {t('ReferralProgramCommunity.description')}
              </p>
            </div>
          </div>
        </div>
      </>
  );
}

export default Community;
