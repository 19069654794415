import React from "react";
//import securityimg from "./../../assets/png/security.png";
import { useTranslation } from "react-i18next";

function Hero() {
  const { t } = useTranslation();

  return (
      <div className="hero-bg3">
        <div className="py-14 2xl:py-20">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 pt-20 xl:pt-40 font-poppins">
            <div className="flex md:flex-row flex-col justify-between gap-6">
              <div className="max-w-[748px] lg:mt-20">
                <h1 className="text-white font-bold !leading-[120%] text-3xl sm:text-4xl xl:text-6xl 2xl:7xl my-4">
                  {t("securityHero.title")}
                </h1>
                <p className="text-white text-base sm:text-lg font-normal !leading-[180%] opacity-80 xl:pe-20">
                  {t("securityHero.description")}
                </p>
                <h3 className="my-4 md:my-5 lg:mt-8 text-white text-lg sm:text-xl lg:text-2xl font-graphik_semibold">
                  {t("securityHero.msb_license_title")}
                </h3>
                <p className="text-white text-base font-normal !leading-[180%] opacity-80 xl:pe-20">
                  {t("securityHero.msb_license_description")}
                </p>
                <ul className="flex flex-col mt-4 md:mt-6 gap-2 sm:gap-3 2xl:gap-6 list-disc ps-4 md:ps-5 white-dot">
                  <li className="text-white text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t("securityHero.overview_title")}:{" "}
                  </span>
                    {t("securityHero.overview_description")}
                  </li>
                  <li className="text-white text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t("securityHero.licensing_authority_title")}:{" "}
                  </span>
                    {t("securityHero.licensing_authority_description")}
                  </li>
                  <li className="text-white text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t("securityHero.compliance_title")}:{" "}
                  </span>
                    {t("securityHero.compliance_description")}
                  </li>
                </ul>
              </div>
              {/* <div className="xl:max-w-[523px] lg:min-w-[500px] max-w-[280px] md:max-w-[350px] mx-auto md:mx-0 relative z-20">
                <img src={securityimg} alt="Security Image" className="w-full" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
  );
}

export default Hero;
