import React from "react";
import tcimg from "./../../assets/png/tcimg.png";
import { useTranslation } from "react-i18next";

function TAndC() {
  const { t } = useTranslation();

  return (
      <>
        <div className="py-12 md:py-16 xl:py-20">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
            <div className="flex flex-col-reverse lg:flex-row justify-between gap-8 items-center">
              <div className="max-w-[280px] sm:max-w-[400px] lg:max-w-[500px] xl:max-w-[755px]">
                <img src={tcimg} alt={t("TAndC.alt")} className="w-full" />
              </div>
              <div className="lg:max-w-[600px]">
                <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] capitalize">
                  {t("TAndC.title")}
                </h2>
                <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%]">
                  {t("TAndC.description")}
                </p>
                <ul className="flex flex-col gap-3 sm:gap-4 2xl:gap-8 list-disc ps-4 md:ps-5">
                  <li className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t("TAndC.eligibility.title")}:{" "}
                  </span>
                    {t("TAndC.eligibility.description")}
                  </li>
                  <li className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t("TAndC.referral_guidelines.title")}:{" "}
                  </span>
                    {t("TAndC.referral_guidelines.description")}
                  </li>
                  <li className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t("TAndC.dispute_resolution.title")}:{" "}
                  </span>
                    {t("TAndC.dispute_resolution.description")}
                  </li>
                </ul>{" "}
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default TAndC;
