import React from "react";
import { CrossIcon, RightArrowIcon } from "../helper/Icon";
import { headerLinks } from "../helper/Helper";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const MobileHeader = ({ toggleOpen }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="h-screen w-screen lg:hidden flex items-center justify-center flex-col relative">
        <button className="absolute top-10 right-4" onClick={toggleOpen}>
          <CrossIcon />
        </button>

        <div className="items-center flex flex-col gap-8 ">
          {headerLinks.map((item, index) => (
            <Link
              key={index}
              to={item.link}
              onClick={toggleOpen}
              className="text-white text-base transition-all duration-300 ease-in-out hover:opacity-70  font-normal"
            >
                {t(item.name)}
            </Link>
          ))}
        </div>
        <div className="mt-8 sm:hidden ">
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault(); // 阻止默认行为
              window.open("https://app.sanketsfx.com", "_blank", "noopener noreferrer");
            }}
            className="bg-white px-5 xl:px-7 py-2 xl:py-3.5 rounded-full flex items-center gap-1.5
                 lg:gap-2.5 font-graphik_medium text-primary text-base transition-all duration-300 ease-in-out hover:shadow-xl hover:shadow-primary group"
          >
              {t('common.get_started')}
              <RightArrowIcon />
          </Link>
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
