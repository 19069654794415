import React from "react";
import foreximg from "./../../assets/png/forex-exchange.png";
import { Link } from "react-router-dom";
import {
  DArrowIconGray,
  DownArrowIconwhite,
} from "../../helper/Icon";
import { useTranslation } from "react-i18next";

function ForexExchange() {
  const { t, i18n } = useTranslation();

  // 创建跳转链接的函数，包含语言参数
  const getStartedUrl = () => {
    const currentLanguageCode = i18n.language;
    return `https://app.sanketsfx.com/#/?language=${currentLanguageCode}`;
  };

  return (
      <>
        <div className="py-12 md:py-16 lg:py-20 xl:py-24 2xl:py-28">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 ">
            <div className="flex flex-col-reverse lg:flex-row justify-between gap-8 items-center">
              <div className="max-w-[280px] sm:max-w-[400px] lg:max-w-[755px]">
                <img src={foreximg} alt="foreximg" className="w-full" />
              </div>
              <div className="lg:max-w-[671px]">
                <p className="capitalize tracking-[0.84px] text-sm text-primary">
                  {t('ForexExchange.why_choose_sankets')}
                </p>
                <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] capitalize">
                <span className="font-graphik_light my-3 sm:my-4">
                  {t('ForexExchange.give_your_trading_strategy')}
                </span>
                </h2>
                <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%]">
                  {t('ForexExchange.our_leading_market_conditions')}
                </p>
                <ul className="flex flex-col gap-3 sm:gap-4 2xl:gap-8 list-disc ps-4 md:ps-5">
                  <li className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium text-black">
                    {t('ForexExchange.low_and_stable_spreads')}
                  </span>{" "}
                    {t('ForexExchange.trade_with_tight_predictable_spreads')}
                  </li>
                  <li className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium text-black">
                    {t('ForexExchange.stop_out_protection')}
                  </span>{" "}
                    {t('ForexExchange.experience_fewer_stop_outs')}
                  </li>
                  <li className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium text-black">
                    {t('ForexExchange.no_overnight_fees')}
                  </span>{" "}
                    {t('ForexExchange.hold_overnight_positions_for_free')}
                  </li>
                  <li className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium text-black">
                    {t('ForexExchange.ultra_fast_execution')}
                  </span>{" "}
                    {t('ForexExchange.execute_trades_in_milliseconds')}
                  </li>
                </ul>{" "}
                <div className="flex my-5 xl:my-8 items-center gap-3 sm:gap-4">
                  <Link
                      to="/"
                      onClick={(e) => {
                        e.preventDefault(); // 阻止默认行为
                        const url = getStartedUrl(); // 动态生成跳转链接
                        window.open(url, "_blank", "noopener noreferrer");
                      }}
                      className="bg-primary text-white px-4 sm:px-5 xl:px-7 py-2 xl:py-3.5 rounded-full flex items-center gap-1.5
                 lg:gap-2.5 font-graphik_medium text-sm sm:text-base transition-all duration-300 ease-in-out hover:shadow-xl group"
                  >
                    {t('common.get_started')}
                    <span>
                    <DownArrowIconwhite />
                  </span>
                  </Link>
                  {/* <Link
                      to="/"
                      onClick={(e) => {
                        e.preventDefault(); // 阻止默认行为
                        window.open("https://t.me/sanketsfx", "_blank", "noopener noreferrer");
                      }}
                      className="bg-transparent border border-[rgba(7,16,30,0.10)] px-4 sm:px-5 xl:px-7 py-2 xl:py-3.5 rounded-full flex items-center gap-1.5
                 lg:gap-2.5 font-graphik_medium text-black text-sm sm:text-base transition-all duration-300 ease-in-out hover:shadow-xl group"
                  >
                    {t('common.join_community')}
                    <span>
                    <DArrowIconGray />
                  </span>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default ForexExchange;
