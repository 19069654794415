import React from "react";
import { useTranslation } from "react-i18next";

function RiskAlertAgreement() {
  const { t } = useTranslation();

  return (
      <>
        <div className="bg-primary pb-20 pt-40">
          <h1 className="text-white text-center font-bold !leading-[120%] text-3xl sm:text-4xl xl:text-6xl 2xl:7xl my-4">
            {t('RiskAlertAgreement.title')}
          </h1>
        </div>
        <div className="bg-white my-16 md:my-20 pb-20">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
            <p className="text-black text-base sm:text-lg lg:text-xl">
              {t('RiskAlertAgreement.introduction')}
            </p>
            <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
              <li className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {t('RiskAlertAgreement.leverage_risk.title')}
                </span>{" "}
                {t('RiskAlertAgreement.leverage_risk.description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {t('RiskAlertAgreement.risk_reduction_orders_strategies.title')}
                </span>{" "}
                {t('RiskAlertAgreement.risk_reduction_orders_strategies.description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {t('RiskAlertAgreement.fees_charges.title')}
                </span>{" "}
                {t('RiskAlertAgreement.fees_charges.description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {t('RiskAlertAgreement.electronic_trading_risks.title')}
                </span>{" "}
                {t('RiskAlertAgreement.electronic_trading_risks.description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {t('RiskAlertAgreement.limitation_liability.title')}
                </span>{" "}
                {t('RiskAlertAgreement.limitation_liability.description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {t('RiskAlertAgreement.margin_requirements.title')}
                </span>{" "}
                {t('RiskAlertAgreement.margin_requirements.description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {t('RiskAlertAgreement.quotation_errors.title')}
                </span>{" "}
                {t('RiskAlertAgreement.quotation_errors.description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {t('RiskAlertAgreement.third_party_authorization.title')}
                </span>{" "}
                {t('RiskAlertAgreement.third_party_authorization.description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {t('RiskAlertAgreement.bankruptcy_risk_disclosure.title')}
                </span>{" "}
                {t('RiskAlertAgreement.bankruptcy_risk_disclosure.description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
                <span className="text-primary font-graphik_semibold">
                  {t('RiskAlertAgreement.volatile_market_conditions.title')}
                </span>{" "}
                {t('RiskAlertAgreement.volatile_market_conditions.description')}
              </li>
            </ul>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('RiskAlertAgreement.closing_statement')}
            </p>
          </div>
        </div>
      </>
  );
}

export default RiskAlertAgreement;
