import React from "react";
import { numberData } from "../../helper/Helper";
import { Link } from "react-router-dom";
import { RightIcon } from "../../helper/Icon";
import { useTranslation } from "react-i18next";

function NumberSet() {
  const { t } = useTranslation();

  return (
      <>
        <div className="max-w-[1640px] mx-auto sm:px-6 lg:px-8 xl:px-10 relative z-10 -mt-20 md:mt-[-250px] xl:mt-[-80px]  md:mt-[-57px] ">
          <div className="shadow-[-11px_-6px_250px_0px_rgba(8,13,25,0.12)] bg-white rounded-[20px] py-12 px-4 xl:py-20 xl:px-20" >
            <div className=" md:pt-8" >
              <div className="flex justify-between pb-10"  >
                {numberData.map((item, index) => (
                    <div key={index} className="flex flex-col text-center"  >
                      <h2 className="text-black capitalize font-graphik_medium text-xl sm:text-3xl xl:text-4xl 2xl:text-[54px]">
                        {item.number}
                      </h2>
                      <p className="text-sm text-secondary my-1">
                        {t(item.title)}
                      </p>
                    </div>
                ))}
              </div>
            </div>
            <div className="pt-10 md:pt-20 border-t border-[rgba(7,16,30,0.10)]">
              <div className="flex md:flex-row flex-col justify-between md:pb-6 gap-y-8">
                <div className="flex flex-col gap-2 sm:gap-3 w-full md:w-1/3 md:border-r border-[rgba(7,16,30,0.10)] md:max-w-[424px] md:pe-6">
                  <h3 className="text-lg sm:text-xl xl:text-[22px] text-black font-graphik_semibold">
                    {t('NumberSet.regulated_resilient_responsible')}
                  </h3>
                  <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                    {t('NumberSet.regulated_by_fincen')}
                  </p>
                  <Link
                      to={"/company"}
                      className="flex items-center gap-2 xl:gap-3 group transition-all duration-300 ease-in-out text-base text-[#122196] font-graphik_medium"
                  >
                    {t('NumberSet.learn_more')}
                    <span className="transition-all duration-300 ease-in-out group-hover:translate-x-1">
                    <RightIcon />
                  </span>
                  </Link>
                </div>
                <div className="flex flex-col gap-2 sm:gap-3 w-full md:w-1/3 md:border-r border-[rgba(7,16,30,0.10)] md:max-w-[424px] md:px-6">
                  <h3 className="text-lg sm:text-xl xl:text-[22px] text-black font-graphik_semibold">
                    {t('NumberSet.account_security_client_protection')}
                  </h3>
                  <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                    {t('NumberSet.committed_to_providing_secure_trading')}
                  </p>
                  <Link
                      to={"/security"}
                      className="flex items-center gap-2 xl:gap-3 group transition-all duration-300 ease-in-out text-base text-[#122196] font-graphik_medium"
                  >
                    {t('NumberSet.learn_more')}
                    <span className="transition-all duration-300 ease-in-out group-hover:translate-x-1">
                    <RightIcon />
                  </span>
                  </Link>
                </div>
                <div className="flex flex-col gap-2 sm:gap-3 w-full md:w-1/3 md:max-w-[424px] md:ps-6">
                  <h3 className="text-lg sm:text-xl xl:text-[22px] text-black font-graphik_semibold">
                    {t('NumberSet.introducing_broker_program')}
                  </h3>
                  <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                    {t('NumberSet.become_a_partner')}
                  </p>
                  <Link
                      to={"/referral-program"}
                      className="flex items-center gap-2 xl:gap-3 group transition-all duration-300 ease-in-out text-base text-[#122196] font-graphik_medium"
                  >
                    {t('NumberSet.learn_more')}
                    <span className="transition-all duration-300 ease-in-out group-hover:translate-x-1">
                    <RightIcon />
                  </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default NumberSet;
