import React from "react";
//import companyimg from "./../../assets/png/company.png";
import { useTranslation } from 'react-i18next';

function Hero() {
  const { t } = useTranslation();
  return (
    <>
      <div className="hero-bg5 hero-bg-2">
        <div className=" py-14 2xl:py-20">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10  pt-20 xl:pt-40 font-poppins ">
            <div className="flex md:flex-row flex-col justify-between gap-6">
              <div className="max-w-[748px] lg:mt-20 ">
                <h1 className="text-white  font-bold !leading-[120%] text-3xl sm:text-4xl xl:text-6xl 2xl:7xl my-4">
                  {t('CompanyHero.company_title')}
                  {" "}
                </h1>
                <p className="text-white text-base sm:text-lg    font-normal !leading-[180%] opacity-80 xl:pe-20">
                  {t('CompanyHero.welcome_message')}
                </p>
                <h3 className="my-4 md:my-5 lg:mt-8 text-white text-lg sm:text-xl lg:text-2xl font-graphik_semibold ">
                  {t('CompanyHero.why_are_we_here')}
                </h3>{" "}
                <p className="text-white text-base  lg:mt-3  font-normal !leading-[180%] opacity-80 xl:pe-20">
                  {t('CompanyHero.technology_opportunities')}
                </p>

                <p className="text-white text-base   lg:mt-3 font-normal !leading-[180%] opacity-80 xl:pe-20">
                <p>
                  {t('CompanyHero.equal_investment_opportunities')}
                </p>
                  {t('CompanyHero.investment_starting_point')}
                </p>

                <p className="text-white text-base  lg:mt-3 font-normal !leading-[180%] opacity-80 xl:pe-20">
                  {t('CompanyHero.financial_literacy_issues')}
                </p>
              </div>
              {/* <div className="xl:max-w-[700px] lg:min-w-[500px] max-w-[280px] md:max-w-[350px] mx-auto md:mx-0 relative z-20">
                <img src={companyimg} alt="phone img" className="w-full" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
