import React from "react";
import workimg from "./../../assets/png/work-img.png";
import { useTranslation } from "react-i18next";

function HowWork() {
  const { t } = useTranslation();

  return (
      <>
        <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 pt-10 md:pt-14">
          <div className="text-center">
            <p className="text-sm text-primary tracking-[0.84px] uppercase mb-3 lg:mb-4">
              {t('HowWork.revolutionary_technology_architecture')}
            </p>
            <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] capitalize">
              {t('HowWork.how_it_works')}
            </h2>
            <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%] max-w-[824px] text-center mx-auto">
              {t('HowWork.industry_leading_technology')}
            </p>
          </div>
          <div className="flex lg:flex-row flex-col gap-y-8 justify-between h-full mt-10 lg:mt-20">
            <div className="flex flex-col justify-around lg:text-end lg:mt-16 xl:mt-20 order-1 gap-y-6">
              <div className="lg:max-w-[420px] lg:-mr-10">
                <h3 className="text-lg sm:text-xl xl:text-[22px] text-black font-graphik_semibold">
                  {t('HowWork.key_technology_differentiators')}
                </h3>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.ultra_low_latency_matching_engine')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.superior_exchange_performance')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.processing_capacity')}
                </p>
              </div>
              <div className="my-auto lg:max-w-[420px] lg:-ml-6 2xl:-ml-16">
                <h3 className="text-lg sm:text-xl xl:text-[22px] text-black font-graphik_semibold">
                  {t('HowWork.latency_capacity_resilience')}
                </h3>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.order_matching_latency')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.high_capacity_architecture')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.high_performance_messaging')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.high_availability_resilience')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.scalable_architecture_growth')}
                </p>
              </div>
            </div>
            <div className="mx-auto lg:mx-0 max-w-[300px] lg:max-w-[500px] xl:max-w-[600px] 2xl:max-w-[768px] relative -z-[10] lg:order-2 order-3">
              <img src={workimg} alt="img" className="w-full" />
            </div>
            <div className="flex flex-col justify-around lg:mt-16 xl:mt-20 order-2 lg:order-3 gap-y-6">
              <div className="lg:max-w-[420px] lg:-ml-10">
                <h3 className="text-lg sm:text-xl xl:text-[22px] text-black font-graphik_semibold">
                  {t('HowWork.leveraging_technology_private_rooms')}
                </h3>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.supports_private_room_trading')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.configurable_environments')}
                </p>
              </div>
              <div className="my-auto lg:max-w-[420px]">
                <h3 className="text-lg sm:text-xl xl:text-[22px] text-black font-graphik_semibold">
                  {t('HowWork.benefits_trading_firm_liquidity')}
                </h3>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.exchange_quality_execution')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.no_last_look_rejections')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.configurable_fill_rate')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.price_improvement_standard')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.control_execution_parameters_costs')}
                </p>
                <p className="text-sm sm:text-base !leading-[160%] text-secondary">
                  {t('HowWork.real_time_market_data')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default HowWork;
