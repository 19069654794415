import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const customLanguageDetector = {
    name: 'querystring',
    lookup(options) {
        let found;
        if (typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search);
            found = params.get(options.lookupQuerystring);
        }
        return found;
    }
};

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ['en', 'zh-TW', 'ja', 'tr', 'es'], // 支持的语言列表，确保一致
        fallbackLng: 'zh-TW', // 默认语言
        debug: true, // 开启调试模式
        backend: {
            loadPath: '/locales/{{lng}}/translation.json', // 语言文件的加载路径
        },
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'], // 语言检测顺序
            lookupQuerystring: 'language', // 查询参数的名称
            caches: ['cookie'], // 语言缓存方式
            checkWhitelist: true // 检查支持的语言列表
        },
        react: {
            useSuspense: false, // 是否使用 suspense
        },
    });

export default i18n;
