import React from "react";
import { useTranslation } from "react-i18next";

function InformationDisclosureStatement() {
  const { t } = useTranslation();

  return (
      <>
        <div className="bg-primary pb-20 pt-40">
          <h1 className="text-white text-center font-bold !leading-[120%] text-3xl sm:text-4xl xl:text-6xl 2xl:7xl my-4">
            {t('InformationDisclosureStatement.title')}
          </h1>
        </div>
        <div className="bg-white my-16 md:my-20 pb-20">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
            <li className="text-black text-base sm:text-lg lg:text-xl list-none">
            <span className="text-primary font-graphik_semibold">
              {t('InformationDisclosureStatement.non_public_personal_information_collected')}
            </span>
            </li>
            <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                1. {t('InformationDisclosureStatement.client_provided_information')}:
              </span>{" "}
                {t('InformationDisclosureStatement.client_provided_information_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                2. {t('InformationDisclosureStatement.identity_verification_information')}:
              </span>{" "}
                {t('InformationDisclosureStatement.identity_verification_information_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                3. {t('InformationDisclosureStatement.transaction_related_information')}:
              </span>{" "}
                {t('InformationDisclosureStatement.transaction_related_information_description')}
              </li>
              <li className="text-black text-base sm:text-lg lg:text-xl">
              <span className="text-primary font-graphik_semibold">
                4. {t('InformationDisclosureStatement.website_platform_usage_information')}:
              </span>{" "}
                {t('InformationDisclosureStatement.website_platform_usage_information_description')}
              </li>
            </ul>
            <br />
            <li className="text-black text-base sm:text-lg lg:text-xl list-none">
            <span className="text-primary font-graphik_semibold">
              {t('InformationDisclosureStatement.disclosure_information')}
            </span>
            </li>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('InformationDisclosureStatement.disclosure_information_description')}
            </p>
            <br />
            <li className="text-black text-base sm:text-lg lg:text-xl list-none">
            <span className="text-primary font-graphik_semibold">
              {t('InformationDisclosureStatement.confidentiality_information_security_procedures')}
            </span>
            </li>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('InformationDisclosureStatement.confidentiality_information_security_procedures_description')}
            </p>
          </div>
        </div>
      </>
  );
}

export default InformationDisclosureStatement;
