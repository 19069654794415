import React from "react";
import { useTranslation } from "react-i18next";

function Disclaimer() {
  const { t } = useTranslation();

  return (
      <>
        <div className="bg-primary pb-20 pt-40">
          <h1 className="text-white text-center font-bold !leading-[120%] text-3xl sm:text-4xl xl:text-6xl 2xl:7xl my-4">
            {t('Disclaimer.disclaimer_title')}
          </h1>
        </div>
        <div className="bg-white my-16 md:my-20 pb-20">
          <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10">
            <p className="text-black text-base sm:text-lg lg:text-xl">
              {t('Disclaimer.disclaimer_intro')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.cfds_warning')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.website_information')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.acceptance_statement')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.accuracy_disclaimer')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.risk_warning')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.data_delay_disclaimer')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.platform_liability')}
            </p>
            <ul className="flex flex-col gap-4 sm:gap-6 mt-6 md:mt-8">
              {(Array.isArray(t('Disclaimer.liability_conditions', { returnObjects: true })) ? t('Disclaimer.liability_conditions', { returnObjects: true }) : []).map((item, index) => (
                  <li key={index} className="text-black text-base sm:text-lg lg:text-xl">
                    {item}
                  </li>
              ))}
            </ul>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.abnormal_transaction_disclaimer')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.profitability_disclaimer')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.jurisdiction_statement')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.legal_fees_statement')}
            </p>
            <p className="text-black text-base sm:text-lg lg:text-xl mt-6">
              {t('Disclaimer.interpretation_statement')}
            </p>
          </div>
        </div>
      </>
  );
}

export default Disclaimer;
