import React from "react";
import Hero from "./Hero";
import ReferralProcess from "./ReferralProcess";
import Rewards from "./Rewards";
import TAndC from "./TAndC";
import Community from "./Community";

function ReferralProgram() {
  return (
    <>
      <Hero />
      <ReferralProcess />
      <Rewards />
      <TAndC />
      <Community />
    </>
  );
}

export default ReferralProgram;
