import { FbIcon, InstaIcon, LinkedinIcon, MIcon, YoutubeIcon } from "./Icon";

export const headerLinks = [
  { name: "Helper.header_links.home", link: "/" },
  { name: "Helper.header_links.trading", link: "/trading" },
  { name: "Helper.header_links.security", link: "/security" },
  { name: "Helper.header_links.company", link: "/company" },
  { name: "Helper.header_links.referral_program", link: "/referral-program" },
];
export const headerLinks_bak = [
  { name: "Home", link: "/" },
  { name: "Trading", link: "/trading" },
  { name: "Security", link: "/security" },
  { name: "Company", link: "/company" },
  { name: "Referral program", link: "/referral-program" },
  // { name: "Top right corner", link: "/" },
];
export const mediaLinks = [
  { icon: <MIcon />, link: "/" },
  { icon: <InstaIcon />, link: "/" },
  { icon: <FbIcon />, link: "/" },
  { icon: <LinkedinIcon />, link: "/" },
  { icon: <YoutubeIcon />, link: "/" },
];
/*export const numberData = [
  { number: "10K1", title: "Active traders" },
  { number: "$30 Million", title: "Trading volume" },
  { number: "$500K", title: "Commission paid monthly" }
];*/
export const numberData = [
  { number: "10K", title: "Helper.number_data.active_traders" },
  { number: "$30 Million", title: "Helper.number_data.trading_volume" },
  { number: "$500K", title: "Helper.number_data.commission_paid_monthly" }
];
export const threeTabdata = [
  { name: "common.register_and_verify_profile" },
  { name: "common.deposit_funds" },
  { name: "common.enter_the_market" },
];
