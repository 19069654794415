import React from "react";
import documentimg from "./../../assets/png/documentimg.png";
import {useTranslation} from "react-i18next";

function LegalDocuments() {
  const { t } = useTranslation();
  return (
    <>
      <div className="py-12 md:py-16  xl:py-20">
        <div className="max-w-[1640px] mx-auto px-4 sm:px-6 lg:px-8 xl:px-10 ">
          <div className="flex flex-col-reverse lg:flex-row justify-between gap-8 items-center">
            <div className="max-w-[280px] sm:max-w-[400px] lg:max-w-[500px] xl:max-w-[755px]">
              <img src={documentimg} alt="foreximg" className="w-full" />
            </div>
            <div className="lg:max-w-[600px]  ">
              <h2 className="text-2xl sm:text-3xl xl:text-4xl 2xl:text-[44px] font-graphik_bold !leading-[120%] capitalize ">
                {t('LegalDocuments.prime_brokerage_services')}
              </h2>
              <p className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%] ">
                {t('LegalDocuments.services_description')}
              </p>
              <div className="my-3 sm:my-4 lg:my-5 text-base text-secondary leading-[160%] ">
                <div className="text-secondary text-sm leading-[160%] ">
                  <p>
                  <span className="font-graphik_medium">
                    {" "}
                    {t('LegalDocuments.bespoke_liquidity_solutions')}
                    {" "}
                  </span>{" "}
                  </p>
                  {t('LegalDocuments.bespoke_liquidity_solutions_description')}
                </div>
                <div className="text-secondary text-sm leading-[160%]">
                  <span className="font-graphik_medium">
                    {t('LegalDocuments.white_label_opportunities')}
                  </span>{" "}
                  {t('LegalDocuments.white_label_opportunities_description')}
                </div>
                <div className="text-secondary text-sm leading-[160%]">
                  <p>
                  <span className="font-graphik_medium">
                     {t('LegalDocuments.highly_sophisticated_margin_solutions')}
                  </span>{" "}
                  </p>
                  {t('LegalDocuments.highly_sophisticated_margin_solutions_description')}
                </div>{" "}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalDocuments;
