import React from "react";
import Hero from "./Hero";
import OrderExecution from "./OrderExecution";
import Margin from "./Margin";
import TradingHours from "./TradingHours";
import Community from "./Community";

function Trading() {
  return (
    <>

      <Hero />
      <OrderExecution />
      <Margin />
      <TradingHours />
      <Community />
    </>
  );
}

export default Trading;
